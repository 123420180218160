<template>
  <div v-if="this.placementKeys[this.locale]" class="ad-container">
    <div class="ad-container-inner">
      <div
        :id="`${idName}_${elementId}`"
        :style="inlineStyle"
        :class="idName"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    elementId: {
      type: Number,
      default: 0,
    },
    idName: {
      type: String,
      default: '',
    },
    query: {
      type: String,
      default: '',
    },
    placementKeys: {
      type: Object,
      default: () => {}
    },
    inlineStyle: {
      type: String,
      default: '',
    },
    locale: {
      type: String,
      default: '',
    }
  },
  data: () => ({
    adScriptsAvailable: false,
  }),
  computed: {
    ...mapState({
      isMobile: state => state.isMobile,
    })
  },
  mounted() {
    if (!window) return;
    if (!this.placementKeys[this.locale]) return;
    if (typeof window.uAd === 'object') {
      this.initAds();
    }
  /* eslint-disable */ else
    (function(d, t) {
      const g = d.createElement(t);
      const s = d.getElementsByTagName(t)[0];
      g.src = 'https://s.d.adup-tech.com/jsapi';
      g.async = true;
      s.parentNode.insertBefore(g, s); 
    })(document, 'script');
},
  methods: {
    initAds() {
      let adupPlacementKey;
      if (this.placementKeys[this.locale][1] && this.isMobile) {
        adupPlacementKey = this.placementKeys[this.locale][1]; // Mobile
      } else {
        adupPlacementKey = this.placementKeys[this.locale][0];
      }

      window.uAd.embed(`${this.idName}_${this.elementId}`, {
        placementKey: adupPlacementKey,
        query: this.query,
        responsive: true,
      });
    },
  },
};
</script>
