/**
 * AdSlots Mixin
 */
import { mapState } from 'vuex';

export default {
  data: () => ({
    adProvider: 'stroeer',
    adZoneSet: false,
    adSlotsFinalized: false,
    adScriptsAvailable: false,
    adSlotsUnregistered: false,
    adSlotsRegistered: false,
    windowLoaded: false,
    domNodesLoaded: false
  }),
  computed: {
    ...mapState({
      stroeerLoaded: (state) => state.ads.stroeerLoaded,
      stroeerConsentAvailable: (state) => state.ads.stroeerConsentAvailable,
      stroeerIsCheckingConsent: (state) => state.ads.stroeerIsCheckingConsent,
      stroeerTriggerAdSlots: (state) => state.ads.stroeerTriggerAdSlots
    })
  },
  mounted() {
    if (this.$route.query && this.$route.query.disableAds) {
      return;
    }
    this.log('AdSlotsMixin mounted');
    this.log({
      stroeerLoaded: this.stroeerLoaded,
      stroeerConsentAvailable: this.stroeerConsentAvailable,
      windowLoaded: this.windowLoaded
    });

    if (!this.windowLoaded) {
      if (document.readyState === 'complete') {
        this.windowLoaded = true;
        this.adSetupCallback();
      } else {
        window.addEventListener('load', () => {
          this.windowLoaded = true;
          this.adSetupCallback();
        });
      }
    } else {
      this.adSetupCallback();
    }
  },
  updated() {
    if (this.$route.query && this.$route.query.disableAds) {
      return;
    }
    if (!this.windowLoaded) {
      if (document.readyState === 'complete') {
        this.windowLoaded = true;
      }
    }
    if (!this.domNodesLoaded) this.domNodesComplete();
  },
  watch: {
    dataLoadedForAds(newData) {
      this.log('Data Loaded for Ads:', newData);
      this.setupAdSlots();
    },
    stroeerLoaded(newState) {
      this.log('new value for StroeerLoaded:', newState);
      this.setupAdSlots();
    },
    domNodesLoaded(newState) {
      this.log('new value for domNodesLoaded:', newState);
      this.setupAdSlots();
    },
    stroeerConsentAvailable(newState) {
      this.log('new value for StroeerConsentAvailable:', newState);
      this.setupAdSlots();
    },
    stroeerIsCheckingConsent(active) {
      this.log('new value for StroeerIsCheckingConsent:', active);
      if (active) {
        this.log('StroeerIsCheckingConsent: unregisterAllSlots');
        this.unregisterAllSlots();
      } else {
        this.setupAdSlots();
      }
    },
    stroeerTriggerAdSLots(timestamp) {
      this.log('new value for StroeerTriggerAdSlots:', timestamp);
      if (timestamp) {
        this.log('StroeerTriggerAdSLots: unregisterAllSlots');
        this.unregisterAllSlots();
        this.log('StroeerTriggerAdSLots: setupAdSlots');
        this.setupAdSlots();
      }
    }
  },
  destroyed() {
    if (!window.SDG) {
      return;
    }
    this.unregisterAllSlots('force');
    this.log('💥 destroyed!');
  },
  methods: {
    adSetupCallback() {
      if (window && window.SDG) {
        this.log('AdSlotsMixin mounted + found Stroeer');
        this.adScriptsAvailable = true;
        if (this.stroeerConsentAvailable) {
          this.log('AdSlotsMixin mounted + consentAvailable');
          this.setupAdSlots();
        }
      }
    },
    log(...content) {
      if (this.$config.DEBUG_LOGS) {
        /* eslint-disable */
        console.log(
          '%cHG-AdSlotsMixin:',
          'color:white; background-color:teal; border-radius: 4px; padding: 2px;',
          ...content
        );
        /* eslint-enable */
      }
    },
    setupAdSlots() {
      if (!this.adSlotConfig) return;
      if (
        this.stroeerLoaded &&
        this.stroeerConsentAvailable &&
        this.dataLoadedForAds &&
        this.windowLoaded &&
        this.domNodesLoaded
      ) {
        this.log('setupAdSlots: 👊 go for it!', {
          stroeerLoaded: this.stroeerLoaded,
          stroeerConsentAvailable: this.stroeerConsentAvailable,
          dataLoadedForAds: this.dataLoadedForAds,
          adSlotsRegistered: this.adSlotsRegistered,
          windowLoaded: this.windowLoaded,
          domNodesLoaded: this.domNodesLoaded
        });
        if (!this.adSlotsUnregistered) {
          this.log('setupAdSlots: unregisterAllSlots');
          this.unregisterAllSlots();
        }
        if (!this.adSlotsRegistered) {
          this.setZone();
          this.registerSlots();
        }
      } else {
        this.log('setupAdSlots: not yet ready', {
          stroeerLoaded: this.stroeerLoaded,
          stroeerConsentAvailable: this.stroeerConsentAvailable,
          dataLoadedForAds: this.dataLoadedForAds,
          adSlotsRegistered: this.adSlotsRegistered,
          windowLoaded: this.windowLoaded,
          domNodesLoaded: this.domNodesLoaded
        });
      }
    },
    finalizeAdSlots() {
      if (this.adSlotsFinalized || !window.SDG) {
        return;
      }
      this.$nextTick();
      window.SDG.Publisher.finalizeSlots();
      this.adSlotsFinalized = true;
      this.log('adSlots finalized!');
    },
    setZone() {
      if (this.adZoneSet || !window.SDG) {
        return;
      }
      window.SDG.Publisher.setZone(this.adSlotConfig.zone);
      this.adZoneSet = true;
      this.log('✅ zone set to: ', this.adSlotConfig.zone);
    },
    registerSlots() {
      if (this.adSlotsRegistered || !window.SDG) {
        return;
      }
      this.log('register new Slots');
      window.addEventListener('metaTagSystemSlotRegistered', (event) => {
        if (!this.adSlotsRegistered) {
          this.log('✅ registered slotName:', event.detail.position);
          this.checkSlots(event.detail.position);
        }
      });
      for (let i = 0; i < this.adSlotConfig.slots.length; i++) {
        const slot = this.adSlotConfig.slots[i];
        if (slot.provider === this.adProvider && slot.loadAd) {
          this.log(
            `${i}- registering slotName: ${slot.stroeerSlotName}' to slotContainer: '${slot.stroeerContainer}'`
          );
          window.SDG.Publisher.registerSlot(
            slot.stroeerSlotName,
            slot.stroeerContainer
          );
        }
      }
    },
    checkSlots(stroeerSlotName) {
      for (let i = 0; i < this.adSlotConfig.slots.length; i++) {
        const slot = this.adSlotConfig.slots[i];
        if (slot.provider === this.adProvider && slot.loadAd) {
          if (slot.stroeerSlotName === stroeerSlotName) {
            slot.slotRegistered = true;
          } else if (!slot.slotRegistered) {
            return;
          }
        }
      }
      this.log('✅ all slots registered');
      this.adSlotsRegistered = true;
      this.log('🔋 loading all Slots');
      window.SDG.Publisher.loadAllSlots(true);
      this.finalizeAdSlots();
    },
    domNodesComplete() {
      if(!this.adSlotConfig) return;
      this.log(this.adSlotConfig);
      const missingSlots = [];
      for (let i = 0; i < this.adSlotConfig.slots.length; i++) {
        const slot = this.adSlotConfig.slots[i];
        if (slot.provider === this.adProvider && slot.loadAd) {
          // check slot.stroeerContainer in DOM
          const bannerSlot = document.getElementById(slot.stroeerContainer);
          if (!bannerSlot)
            missingSlots.push(
              '❌ DOM Node ' + slot.stroeerContainer + ' not found'
            );
        }
      }
      if (missingSlots.length) {
        this.log(missingSlots);
      } else {
        this.log('✅ all DOM Nodes found');
        this.domNodesLoaded = true;
      }
      return missingSlots.length === 0;
    },
    unregisterAllSlots(option) {
      if (!window.SDG) return;
      if (option !== 'force' || this.adSlotsUnregistered) return;
      this.adSlotsUnregistered = true;
      this.log('🚫 unregister previous Slots');
      window.SDG.Publisher.unregisterAllSlots(true);
    }
  }
};
